import { ListDashBoardGlobalRaydeoApiKeys } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetDashboardAPIs = {
  data() {
    return {
      overlay: false,
      noDataTextDashboard: "",
      GetDashboardAPIsItems: [],
    };
  },
  methods: {
    async GetDashboardAPIsItemsMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListDashBoardGlobalRaydeoApiKeys, {
            input: {},
          })
        );
        this.GetDashboardAPIsItems = JSON.parse(
          result.data.ListDashBoardGlobalRaydeoApiKeys
        ).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetDashboardAPIsItems = [];
        this.noDataTextDashboard = error.errors[0].message;
      }
    },
  },
};
