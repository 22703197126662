/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const Get_Current_User_Details = /* GraphQL */ `
  query Get_Current_User_Details($input: GetCurrentUserDetailsInput) {
    Get_Current_User_Details(input: $input)
  }
`;
export const List_Users = /* GraphQL */ `
  query List_Users {
    List_Users
  }
`;
export const ListCategories = /* GraphQL */ `
  query ListCategories($input: ListCategoriesInput) {
    ListCategories(input: $input)
  }
`;
export const List_Organizations = /* GraphQL */ `
  query List_Organizations($input: ListOrganizationsInput) {
    List_Organizations(input: $input)
  }
`;
export const ListBMSManufacturers = /* GraphQL */ `
  query ListBMSManufacturers($input: ListOrganizationsInput) {
    ListBMSManufacturers(input: $input)
  }
`;
export const List_Categories_By_Master_Category = /* GraphQL */ `
  query List_Categories_By_Master_Category(
    $input: ListMasterCategoryBasedSubCategoriesInput
  ) {
    List_Categories_By_Master_Category(input: $input)
  }
`;
export const ListServiceType = /* GraphQL */ `
  query ListServiceType($input: ListServiceTypeInput) {
    ListServiceType(input: $input)
  }
`;
export const ListAllMasterServices = /* GraphQL */ `
  query ListAllMasterServices($input: ListAllMasterServicesInput) {
    ListAllMasterServices(input: $input) {
      status
      data {
        items {
          service_id
          service_name
          master_category_id
          category_id
          service_type_ids
          service_status
          service_ticket_prefix
          category_name
          organization_id
          service_s3_files {
            file_name
            file_type
            file_url
            __typename
          }
          service_credits
          organization_name
          service_created_on
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const List_Logs = /* GraphQL */ `
  query List_Logs {
    List_Logs
  }
`;
export const List_All_Banners = /* GraphQL */ `
  query List_All_Banners {
    List_All_Banners
  }
`;
export const List_All_Organizations_User = /* GraphQL */ `
  query List_All_Organizations_User($input: OrganizationIDInput) {
    List_All_Organizations_User(input: $input)
  }
`;
export const ListS3Details = /* GraphQL */ `
  query ListS3Details {
    ListS3Details
  }
`;
export const ListAllRecharges = /* GraphQL */ `
  query ListAllRecharges {
    ListAllRecharges {
      status
      data {
        items {
          user_email
          payment_id
          payment_initiated_on
          currency
          order_id
          organization_id
          organization_name
          payment_amount
          payment_completed_on
          payment_initiator_id
          payment_initiator_name
          payment_receipt
          payment_status
          user_contact_number
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const ListOrganizationsReport = /* GraphQL */ `
  query ListOrganizationsReport($input: ListOrganizationsReportInput) {
    ListOrganizationsReport(input: $input)
  }
`;
export const ListTutorialFiles = /* GraphQL */ `
  query ListTutorialFiles {
    ListTutorialFiles
  }
`;
export const ListMobileApiKeys = /* GraphQL */ `
  query ListMobileApiKeys {
    ListMobileApiKeys
  }
`;
export const ListModule = /* GraphQL */ `
  query ListModule($input: ListModuleInput) {
    ListModule(input: $input)
  }
`;
export const ListTicketType = /* GraphQL */ `
  query ListTicketType {
    ListTicketType
  }
`;
export const ListNotificationsSettings = /* GraphQL */ `
  query ListNotificationsSettings($input: ListNotificationsSettingsInput) {
    ListNotificationsSettings(input: $input)
  }
`;
export const ListBulkUploadAuditLogs = /* GraphQL */ `
  query ListBulkUploadAuditLogs($input: ListBulkUploadAuditLogsInput) {
    ListBulkUploadAuditLogs(input: $input)
  }
`;
export const ListDashBoardGlobalRaydeoApiKeys = /* GraphQL */ `
  query ListDashBoardGlobalRaydeoApiKeys {
    ListDashBoardGlobalRaydeoApiKeys
  }
`;
export const ListTicketTrailGlobalRaydeoApiKeys = /* GraphQL */ `
  query ListTicketTrailGlobalRaydeoApiKeys {
    ListTicketTrailGlobalRaydeoApiKeys
  }
`;
export const ListOrganizationsUsers = /* GraphQL */ `
  query ListOrganizationsUsers($input: ListOrganizationsUsersInput) {
    ListOrganizationsUsers(input: $input)
  }
`;
export const ListDealerUserAndPartnerUsers = /* GraphQL */ `
  query ListDealerUserAndPartnerUsers(
    $input: ListDealerUserAndPartnerUsersInput
  ) {
    ListDealerUserAndPartnerUsers(input: $input)
  }
`;
export const GetCurrentDayTicketDetails = /* GraphQL */ `
  query GetCurrentDayTicketDetails($input: GetCurrentDayTicketDetailsInput) {
    GetCurrentDayTicketDetails(input: $input)
  }
`;
export const listAttendanceHistory = /* GraphQL */ `
  query ListAttendanceHistory($input: ListAttendanceInput) {
    listAttendanceHistory(input: $input)
  }
`;
export const List_Organizations_Settings = /* GraphQL */ `
  query List_Organizations_Settings($input: ListOrganizationsSettingsInput) {
    List_Organizations_Settings(input: $input)
  }
`;
export const Get_Enterprise_Details = /* GraphQL */ `
  query Get_Enterprise_Details($input: OrganizationIDInput) {
    Get_Enterprise_Details(input: $input)
  }
`;
export const ListMasterCategories = /* GraphQL */ `
  query ListMasterCategories {
    ListMasterCategories
  }
`;
export const List_Categories_By_Selected_Master_Category = /* GraphQL */ `
  query List_Categories_By_Selected_Master_Category(
    $input: ListCategoriesByMasterCategoryInput
  ) {
    List_Categories_By_Selected_Master_Category(input: $input) {
      status
      data {
        items {
          category_id
          category_created_on
          master_category_id
          master_category_name
          category_name
          category_status
          category_type
          category_ticket_prefix
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const List_Open_And_Tickets = /* GraphQL */ `
  query List_Open_And_Tickets($input: ListTicketsInput) {
    List_Open_And_Tickets(input: $input)
  }
`;
