<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-card class="elevation-0">
      <v-toolbar dense class="elevation-0 heading1">
        Raydeo Integration
      </v-toolbar>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="12" xs="12" sm="12">
            <v-expansion-panels class="mb-4 ml-3 mr-3" focusable>
              <v-expansion-panel dense>
                <v-expansion-panel-header
                  class="font-weight-bold black--text"
                  expand-icon="mdi-menu-down"
                >
                  Ticket Trail Global Keys
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mb-4 my-4">
                    <v-col cols="12" md="3" sm="12" xs="12">
                      <b class="FontSize">Last Updated By :</b>
                    </v-col>
                    <v-col cols="12" md="3" xs="12" sm="12">
                      <div class="FontSize">
                        {{ this.ticket_trail_modified_by_mail }}
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" xs="12">
                      <b class="FontSize">Last Updated On :</b>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12">
                      <div class="FontSize">
                        {{ this.ticket_trail_created_on }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-form ref="form">
                    <v-row no-gutters>
                      <v-col cols="12" md="3" xs="12" sm="12">
                        <b class="FontSize">Ticket Trail Channel API Key :</b>
                      </v-col>
                      <v-col cols="12" md="4" xs="12" sm="12">
                        <v-text-field
                          dense
                          outlined
                          class="FontSize ml-2"
                          :rules="[(v) => !!v || 'Required']"
                          label="Ticket Trail Channel API Key *"
                          v-model="ticket_trail_channel_api_key"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" md="3" xs="12" sm="12">
                        <b class="FontSize">Ticket Trail X API Key :</b>
                      </v-col>
                      <v-col cols="12" md="4" xs="12" sm="12">
                        <v-text-field
                          dense
                          outlined
                          class="FontSize ml-2"
                          v-model="ticket_trail_x_api_key"
                          label="Ticket Trail X API Key *"
                          :rules="[(v) => !!v || 'Required']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      small
                      class="primary white--text"
                      @click="saveTicketTrailKeys()"
                      :loading="loading_ticket_trial_btn"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="12" xs="12" sm="12">
            <v-expansion-panels class="mb-4 ml-3 mr-3" focusable>
              <v-expansion-panel dense>
                <v-expansion-panel-header
                  class="font-weight-bold black--text"
                  expand-icon="mdi-menu-down"
                >
                  Dashboard Global Keys
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mb-4 my-4">
                    <v-col cols="12" md="3" sm="12" xs="12">
                      <b class="FontSize">Last Updated By :</b>
                    </v-col>
                    <v-col cols="12" md="3" xs="12" sm="12">
                      <div class="FontSize">
                        {{ this.modified_dashboard_keys_by_mail }}
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" xs="12">
                      <b class="FontSize">Last Updated On :</b>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12">
                      <div class="FontSize">
                        {{ this.dashboard_keys_created_on }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-form ref="form_dashboard">
                    <v-row no-gutters>
                      <v-col cols="12" md="3" xs="12" sm="12">
                        <b class="FontSize">Dashboard Channel API Key :</b>
                      </v-col>
                      <v-col cols="12" md="4" xs="12" sm="12">
                        <v-text-field
                          dense
                          outlined
                          class="FontSize ml-2"
                          :rules="[(v) => !!v || 'Required']"
                          label="Dashboard Channel API Key *"
                          v-model="dashboard_channel_api_key"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" md="3" xs="12" sm="12">
                        <b class="FontSize">Dashboard X API Key :</b>
                      </v-col>
                      <v-col cols="12" md="4" xs="12" sm="12">
                        <v-text-field
                          dense
                          outlined
                          class="FontSize ml-2"
                          v-model="dashboard_x_api_key"
                          label="Dashboard X API Key *"
                          :rules="[(v) => !!v || 'Required']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      small
                      class="primary white--text"
                      @click="saveDashboardKeys()"
                      :loading="loading_dashboard_btn"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { GetTrialAPIs } from "@/mixins/GetAllTrialAPIList.js";
import { GetDashboardAPIs } from "@/mixins/GetDashboardAPI.js";
import { UpdateGlobalRaydeoApiKeys } from "@/graphql/mutations.js";
export default {
  mixins: [GetTrialAPIs, GetDashboardAPIs],
  components: {
    Overlay,
    Snackbar,
  },
  data: () => ({
    overlay: false,
    loading_dashboard_btn: false,
    loading_ticket_trial_btn: false,
    dashboard_x_api_key: "",
    ticket_trail_x_api_key: "",
    ticket_trail_created_on: "",
    dashboard_channel_api_key: "",
    dashboard_keys_created_on: "",
    ticket_trail_channel_api_key: "",
    ticket_trail_modified_by_mail: "",
    modified_dashboard_keys_by_mail: "",
    SnackBarComponent: {},
    GetTrialAPIsItems: [],
    GetDashboardAPIsItems: [],
  }),
  mounted() {
    this.GetTrialAPIsItemsMethod();
    this.GetDashboardAPIsItemsMethod();
    setTimeout(() => {
      this.ticket_trail_x_api_key = this.GetTrialAPIsItems[0].x_api_key;
      this.ticket_trail_channel_api_key =
        this.GetTrialAPIsItems[0].channel_api_key;
      this.ticket_trail_modified_by_mail =
        this.GetTrialAPIsItems[0].modified_by_email;
      this.ticket_trail_created_on = new Date(
        this.GetTrialAPIsItems[0].modified_by_on * 1000
      ).toLocaleString("en-GB");
      this.modified_dashboard_keys_by_mail =
        this.GetDashboardAPIsItems[0].modified_by_email;
      this.dashboard_keys_created_on = new Date(
        this.GetDashboardAPIsItems[0].modified_by_on * 1000
      ).toLocaleString("en-GB");
      this.dashboard_channel_api_key =
        this.GetDashboardAPIsItems[0].channel_api_key;
      this.dashboard_x_api_key = this.GetDashboardAPIsItems[0].x_api_key;
    }, 1000);
  },
  methods: {
    async saveDashboardKeys() {
      if (this.$refs.form_dashboard.validate()) {
        this.loading_dashboard_btn = true;
        try {
          var inputParams = {
            user_email_id:
              this.$store.getters.get_current_user_details.user_email_id,
            master_id: this.GetDashboardAPIsItems[0].master_id,
            channel_api_key: this.dashboard_channel_api_key,
            x_api_key: this.dashboard_x_api_key,
          };
          let result = await API.graphql(
            graphqlOperation(UpdateGlobalRaydeoApiKeys, {
              input: inputParams,
            })
          );
          var ResultObject = JSON.parse(result.data.UpdateGlobalRaydeoApiKeys);
          if (ResultObject.status == "SUCCESS") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: ResultObject.Status_Message,
            };
            this.GetDashboardAPIsItemsMethod();
            setTimeout(() => {
              this.modified_dashboard_keys_by_mail =
                this.GetDashboardAPIsItems[0].modified_by_email;
              this.dashboard_keys_created_on = new Date(
                this.GetDashboardAPIsItems[0].modified_by_on * 1000
              ).toLocaleString("en-GB");
              this.dashboard_channel_api_key =
                this.GetDashboardAPIsItems[0].channel_api_key;
              this.dashboard_x_api_key =
                this.GetDashboardAPIsItems[0].x_api_key;
            }, 1000);
          }
          this.loading_dashboard_btn = false;
        } catch (error) {
          this.loading_dashboard_btn = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill All Mandatory Fields..!",
        };
      }
    },
    async saveTicketTrailKeys() {
      if (this.$refs.form.validate()) {
        this.loading_ticket_trial_btn = true;
        try {
          var inputParams = {
            user_email_id:
              this.$store.getters.get_current_user_details.user_email_id,
            master_id: this.GetTrialAPIsItems[0].master_id,
            channel_api_key: this.ticket_trail_channel_api_key,
            x_api_key: this.ticket_trail_x_api_key,
          };
          let result = await API.graphql(
            graphqlOperation(UpdateGlobalRaydeoApiKeys, {
              input: inputParams,
            })
          );
          var ResultObject = JSON.parse(result.data.UpdateGlobalRaydeoApiKeys);
          if (ResultObject.status == "SUCCESS") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: ResultObject.Status_Message,
            };
            this.GetTrialAPIsItemsMethod();
            setTimeout(() => {
              this.ticket_trail_x_api_key = this.GetTrialAPIsItems[0].x_api_key;
              this.ticket_trail_channel_api_key =
                this.GetTrialAPIsItems[0].channel_api_key;
              this.ticket_trail_modified_by_mail =
                this.GetTrialAPIsItems[0].modified_by_email;
              this.ticket_trail_created_on = new Date(
                this.GetTrialAPIsItems[0].modified_by_on * 1000
              ).toLocaleString("en-GB");
            }, 1000);
          }
          this.loading_ticket_trial_btn = false;
        } catch (error) {
          this.loading_ticket_trial_btn = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill All Mandatory Fields..!",
        };
      }
    },
  },
};
</script>

<style></style>
